<template>
  <!--  <div class="mineView">-->
  <!--    <cell_title_detail head-title="姓名" :content-title="userData.name"></cell_title_detail>-->
  <!--    <cell_title_detail head-title="科室" :content-title="userData.tenantName"></cell_title_detail>-->
  <!--    <cell_title_detail head-title="账号" :content-title="userData.username"></cell_title_detail>-->
  <!--    <el-button style="float: right;margin-right: 10px;margin-top: 10px" type="danger" @click="showLoginOutMsg">退出登录-->
  <!--    </el-button>-->

  <!--    <dialog-view ref="dialogView" msg="是否退出登录" width="90%vh" @sureAction="sureAction"></dialog-view>-->

  <!--  </div>-->

  <div class="mineView">
    <div class="mineContentView">
      <van-cell center title="单元格">
        <!-- 使用 right-icon 插槽来自定义右侧图标 -->
        <template #right-icon>
          <img :src="photo">
        </template>
      </van-cell>
      <van-cell title="姓名" :value="userData.name"/>
      <van-cell title="科室" :value="userData.tenantName"/>
      <van-cell title="账号" :value="userData.username"/>
      <van-cell @click="showLoginOutMsg" title="注销账号" value=""/>
    </div>

    <dialog-view ref="dialogView" msg="是否注销账号" width="90%vh" :sure-event="sureAction"></dialog-view>

  </div>

</template>

<script>
import Cell_title_detail from '@/components/cell/cell_title_detail/cell_title_detail'
import DialogView from '@/components/dialogView/dialogView'

let userPhoto = require('../../../static/iconImage/yonghu.png')

export default {
  name: 'mine',
  components: { DialogView, Cell_title_detail },
  data() {
    return {
      userData: {
        id: '',
        name: '',
        photo: '',
        tenantId: '',
        tenantName: '',
        username: '',
        userPhoto: userPhoto
      }
    }
  },
  computed: {
    photo: function() {
      if (this.$valueIsExist(this.userData, 'photo') && this.userData['photo'] != '') {
        let photo = this.userData['photo']
        let url = `${window.ipConfig.VUE_APP_BASEURL}/api/image/get?id=${photo}&scale=0.3`
        return url
      }
      return userPhoto
    }
  },
  mounted() {
    document.title = '我的'
    this.$nextTick(() => {
      this.getUserInfo()
    })
  },
  methods: {
    // 获取用户信息
    getUserInfo: function() {
      let oldUserInfo = this.$store.getters.userInfo
      for (const key in oldUserInfo) {
        if (typeof this.userData[key] == 'undefined') {
          continue
        }
        this.userData[key] = oldUserInfo[key]
      }
      return new Promise(resolve => {
        this.$api.userInfo.getUserInfo({}).then(res => {
          let data = res.data
          for (const key in data) {
            if (typeof data[key] == 'undefined') {
              continue
            }
            this.userData[key] = data[key]
          }
          this.$store.commit('setUpUserInfo', this.userData)
          resolve({ result: true })
        }).catch((e) => {
          resolve({ result: false })
        })
      })
    },
    sureAction: function() {
      console.log('~~~')
      this.$store.commit('setUpUserToken', {})
      this.$store.commit('setUpOrgInfo', [])

      let type = 'web'
      if (typeof localStorage['weChatType'] != 'undefined') {
        type = localStorage['weChatType']
      }

      this.$clearLocal()
      if (type != 'weChat') {
        if (typeof localStorage['href'] != 'undefined' && localStorage['href'] != null) {
          window.location.href = localStorage['href']
          return
        }

        this.$router.replace('/login?type=account')
        return
      }
      localStorage.setItem('loginOut', new Date().getTime())
      this.navigateBack()
    },
    showLoginOutMsg: function() {
      this.$refs['dialogView'].showOrHidden(true)

      // let r = confirm('是否退出登录')
      // if (r == true) {
      //   this.$store.commit('setUpUserToken', {})
      //
      //   let type = 'web'
      //   if (typeof localStorage['weChatType'] != 'undefined') {
      //     type = localStorage['weChatType']
      //   }
      //
      //   this.$clearLocal()
      //   if (type != 'weChat') {
      //     this.$router.replace('/login?type=account')
      //     return
      //   }
      //   localStorage.setItem('loginOut', new Date().getTime())
      //   this.navigateBack();
      // }
    }
  }
}
</script>

<style lang="scss" scoped>

.mineView {
  width: 100%;
  height: 100%;
  position: absolute;

  .mineContentView {
    padding: 10px;
    border-radius: 8px;
    width: calc(100% - 20px);
    transform: translate(10px, 10px);
    background-color: white;

    img {
      width: 50px;
      height: 50px;
    }

  }

}

</style>
